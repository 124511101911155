<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-24 11:18:26
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-05 19:30:16
-->
<template>
  <a-card :border="false">
    <div class="">
      <div class="title"><span></span>客户统计总览</div>
      <div class="seatch_condition">
        <div></div>
        <div>
          <a-radio-group v-model="mode" :style="{ marginBottom: '8px' }">
            <a-radio-button value="month">月</a-radio-button>
            <a-radio-button value="season">季</a-radio-button>
            <a-radio-button value="year">年</a-radio-button>
          </a-radio-group>
        </div>
        <div>
          <a-range-picker v-model="chartTime" :allowClear="false" :style="{ width: '200px' }" />
        </div>
      </div>
      <a-row :gutter="30" style="margin-bottom: 12px">
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">新增客户（个）</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">成交客户（个）</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">客户转化率</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="30" style="margin-bottom: 15px">
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">丢单客户（个）</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">意向客户</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">潜在客户</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="30" style="margin-bottom: 15px">
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">平均客单价（万元）</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">零售客户</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card class="customer_add">
            <div class="card_content">
              <div></div>
              <div>
                <div class="card_module_name">项目客户</div>
                <div class="card_module_quantity">120</div>
              </div>
              <div style="margin-top: 30px">
                <div class="card_yoy">同比：+12%</div>
                <div class="card_qoq">环比： -36%</div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <div>
        <div class="title"><span></span>客户漏斗模型</div>
        <div></div>
      </div>
    </div>
  </a-card>
</template>

<script>
export default {
  components: {},
  name: '',
  data() {
    return {
      mode: 'month',
      chartTime: [moment().subtract(1, 'weeks'), moment()],
      chart: {
        chart: {
          type: 'funnel',
          marginRight: 100,
        },
        title: {
          text: '销售漏斗',
          x: -50,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: 'none',
              format: '<b>{point.name}</b> ({point.y:,.0f})',
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              softConnector: true,
            },
            neckWidth: '30%',
            neckHeight: '25%',
            //-- Other available options
            // height: pixels or percent
            // width: pixels or percent
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            name: '用户',
            data: [
              ['客户总计', 305],
              ['潜在', 105],
              ['意向', 92],
              ['丢失', 28],
              ['成交', 80],
            ],
          },
        ],
      },
    }
  },

  computed: {},
  created() {},
  methods: {},
}
</script>

<style lang='scss' scoped>
.title {
  display: flex;
  span {
    width: 5px;
    height: 20px;
    background: rgb(25, 158, 216);
    display: inline-block;
    margin-right: 5px;
  }
}
.seatch_condition,
.card_content {
  display: flex;
  justify-content: space-between;
}
.customer_add {
  background: rgb(45, 182, 244);
  border-radius: 5px;
}
.card {
  &_content {
    color: #ffffff;
    justify-content: space-evenly;
  }
  &_module {
    &_name {
      font-size: 12px;
    }
    &_quantity {
      font-size: 26px;
    }
  }
  &_yoy,
  &_qoq {
    font-weight: bold;
  }
}
</style>